<template>
	<section class="permission">
		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-form :inline="true" :model="filters">
				<span>
					<el-form-item label="店铺ID">
						<el-input v-model.trim="filters.store_id" placeholder="店铺ID" clearable></el-input>
					</el-form-item>
					<el-form-item label="用户名">
						<el-input v-model.trim="filters.username" placeholder="输入用户名" clearable></el-input>
					</el-form-item>
					<el-form-item label="店名">
						<el-input v-model.trim="filters.name" placeholder="输入店名" clearable></el-input>
					</el-form-item>
					<el-form-item label="状态">
						<el-select v-model="filters.channel_status" placeholder="请选择店铺状态" clearable>
							<el-option v-for="item in startList" :key="item.value" :label="item.text" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getList(0)" icon="el-icon-search">查询</el-button>
					</el-form-item>
				</span>
			</el-form>
		</el-col>
		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="store_id" label="店铺ID" width="90" align="center">
			</el-table-column>
			<el-table-column prop="username" label="用户名" width="90" align="center">
			</el-table-column>
			<el-table-column prop="name" label="店铺名称" width="150">
			</el-table-column>
			<el-table-column prop="mobile" label="手机号" width="120" align="center">
			</el-table-column>
			<el-table-column prop="mer_id" label="商户ID" width="100" align="center">
			</el-table-column>
			<el-table-column prop="balance" label="进件状态" width="150" align="center">
				<template slot-scope="scope">
					<!-- '0-提交资料生成子商户号,1-提交审核，2-审核驳回', -->
					<div class="gray" v-if="scope.row.status == -1">未进件</div>
					<div class="r4" v-if="scope.row.status == 0">待审核</div>
					<div class="blue" v-if="scope.row.status == 1">审核中</div>
					<div class="orange" v-if="scope.row.status == 2">审核驳回</div>
					<div class="r2" v-if="scope.row.status == 3">审核中，待商户签约</div>
					<div class="r2" v-if="scope.row.status == 4">支付宝审核通过</div>
				</template>
			</el-table-column>
			<el-table-column width="150" label="进件操作">
				<template slot-scope="scope">
					<el-button type="primary" v-if="scope.row.status == -1" plain size="small" @click="setModule(scope.row.username, scope.row.id)">去进件</el-button>
				</template>
			</el-table-column>
			<el-table-column label="备注">
				<template slot-scope="scope">
					<div>{{scope.row.msg}}</div>
				</template>
			</el-table-column>
		</el-table>

		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-sizes="pagesizes"
					:page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</el-col>
	</section>
</template>

<script>
	import { storeData } from '../../api/api';
	export default {
		data() {
			return {
				filters: {
					store_id:'',
					username:'',
					name:'',
					channel_status: 1
				},
				startList:[
					{ value: -1, text: '未进件' },
					{ value: 0, text: '待审核' },
					{ value: 1, text: '审核中' },
					{ value: 2, text: '审核不通过' },
					{ value: 3, text: '待商户签约' },
					{ value: 4, text: '支付宝审核通过' },
				],
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
			}
		},
		created(){
			//获取列表数据
			this.getList();			
		},
		mounted() {
		},
		methods: {
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取用户列表
			getList(val) {	
                if(val == '0') this.page = 1;
				let para = {
					channel: '收啊收',
					page: this.page,
					pagesize: this.pagesize	//分页条数
				};
				let newObj = {};
				Object.assign(newObj,para,this.filters)
				this.listLoading = true;
				storeData(newObj).then((res) => {
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.data;
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			setModule: function (username, id) {
				// localStorage.setItem('dataListFrome', JSON.stringify(this.filters))
				// localStorage.setItem('dataListPage', this.page)	
						
				this.$router.push({ name: '增加进件', query: { username: username, storeId: id }})
			},
		},
		watch:{},
	}

</script>

<style lang="scss" scoped>
	@import "../../styles/permission.scss";
	::v-deep.cz .el-input__inner{ width: 40px; padding: 0 4px; height: 28px; margin-left: 5px; text-align: center; }
</style>